
          @import "@/assets/scss/mixins/absolute.scss";
          @import "@/assets/scss/mixins/bp.scss";
          @import "@/assets/scss/mixins/ellipsis.scss";
          @import "@/assets/scss/mixins/fixed.scss";
          @import "@/assets/scss/mixins/font.scss";
          @import "@/assets/scss/mixins/grid-gap.scss";
          @import "@/assets/scss/mixins/margin.scss";
          @import "@/assets/scss/mixins/padding.scss";
          @import "@/assets/scss/mixins/radius.scss";
          @import "@/assets/scss/mixins/relative.scss";
          @import "@/assets/scss/mixins/size.scss";
          @import "@/assets/scss/mixins/sticky.scss";
          @import "@/assets/scss/functions/rem.scss";
          @import "@/assets/scss/global/normalize.scss";
          @import "@/assets/scss/global/fonts.scss";
          @import "@/assets/scss/global/custom.scss";
        

















































.header {
  @include fixed(0 0 _ _, 6);
  @include size(100% 60px);
  background: #f5f6fa;
  display: grid;
  align-items: center;
  @include bp(0 7) {
    @include padding(_ 25px _ 0);
    @include grid-gap(16px);
    box-shadow: 0 4px 10px 0 rgba(71, 134, 255, 0.1);
    grid-template-columns: auto min-content min-content;
  }
  @include bp(7) {
    @include grid-gap(25px);
    @include size(calc(100% - 240px) 60px);
    @include padding(_ 25px);
    &:not(.header--offline-state) {
      grid-template-columns: repeat(2, max-content);
    }
    justify-content: space-between;
  }
  &--offline-state {
    @include bp(7 17) {
      grid-template-columns: repeat(2, max-content);
    }
    @include bp(17) {
      grid-template-columns: repeat(3, max-content);
    }
  }
  .dash--compact & {
    @include bp(7) {
      @include size(calc(100% - 60px) _);
    }
  }
  &::after {
    @include bp(7) {
      @include absolute(_ _ 0 20px, 1);
      @include size(calc(100% - 40px) 1px);
      content: '';
      background: #d1dbe4;
    }
  }

  .dash-header-button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
}
.offline-state {
  background-color: #dc5555;
  color: white;
  padding: rem(7px) rem(15px) rem(7px) rem(15px);
  border-radius: rem(7px);
  width: 100%;
  &--bp-small {
    @include bp(17) {
      display: none;
    }
  }
  &--bp-large {
    @include bp(0 17) {
      display: none;
    }
  }
}
