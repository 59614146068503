
          @import "@/assets/scss/mixins/absolute.scss";
          @import "@/assets/scss/mixins/bp.scss";
          @import "@/assets/scss/mixins/ellipsis.scss";
          @import "@/assets/scss/mixins/fixed.scss";
          @import "@/assets/scss/mixins/font.scss";
          @import "@/assets/scss/mixins/grid-gap.scss";
          @import "@/assets/scss/mixins/margin.scss";
          @import "@/assets/scss/mixins/padding.scss";
          @import "@/assets/scss/mixins/radius.scss";
          @import "@/assets/scss/mixins/relative.scss";
          @import "@/assets/scss/mixins/size.scss";
          @import "@/assets/scss/mixins/sticky.scss";
          @import "@/assets/scss/functions/rem.scss";
          @import "@/assets/scss/global/normalize.scss";
          @import "@/assets/scss/global/fonts.scss";
          @import "@/assets/scss/global/custom.scss";
        




































































.version-checker {
  @include fixed(0 _ _ 50%, 999);
  @include size(400px _);
  @include padding(15px 10px);
  @include grid-gap(15px);
  transform: translate(-50%, 0);
  background: #6599fe;
  text-align: center;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  display: grid;
  &__wr {
    @include grid-gap(20px);
    display: grid;
    grid-template-columns: max-content max-content;
    justify-content: center;
    text-align: left;
    align-items: center;
  }
  &__text {
    @include font(500 18px "Roboto");
    color: #fff;
    margin: 0;
    padding: 0;
    &--versions {
      @include font(400 15px "Roboto");
      @include grid-gap(5px);
      display: grid;
    }
  }
  &__strong {
    @include font(600 15px "Roboto");
  }
  &__btn {
    @include font(500 14px "Roboto");
    @include size(auto 40px);
    @include radius(4px);
    @include padding(0 15px);
    cursor: pointer;
    margin: 0;
    color: #6599fe;
    border: none;
    background: #fff;
    transition: 150ms;
    &:hover {
      transform: scale(1.05);
    }
  }
}
