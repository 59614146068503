
          @import "@/assets/scss/mixins/absolute.scss";
          @import "@/assets/scss/mixins/bp.scss";
          @import "@/assets/scss/mixins/ellipsis.scss";
          @import "@/assets/scss/mixins/fixed.scss";
          @import "@/assets/scss/mixins/font.scss";
          @import "@/assets/scss/mixins/grid-gap.scss";
          @import "@/assets/scss/mixins/margin.scss";
          @import "@/assets/scss/mixins/padding.scss";
          @import "@/assets/scss/mixins/radius.scss";
          @import "@/assets/scss/mixins/relative.scss";
          @import "@/assets/scss/mixins/size.scss";
          @import "@/assets/scss/mixins/sticky.scss";
          @import "@/assets/scss/functions/rem.scss";
          @import "@/assets/scss/global/normalize.scss";
          @import "@/assets/scss/global/fonts.scss";
          @import "@/assets/scss/global/custom.scss";
        











































































































































































































































.sidebar {
  background: #fff;
  overflow-y: scroll;
  direction: rtl;
  @include bp(0 7) {
    @include fixed(60px _ _ 0, 3);
    @include size(100% calc(100vh - 60px));
  }
  @include bp(7) {
    @include size(240px calc(100vh - 60px));
    @include fixed(_ _ 0 0, 3);
    transition: width 200ms;
  }
  .dash--compact & {
    @include bp(7) {
      @include size(60px _);
    }
  }
  .dash--nav-hover & {
    @include bp(7) {
      @include size(240px _);
    }
  }
  .dash--nav-not-hover & {
    @include bp(7) {
      padding: 0;
    }
  }
  .dash--sidebar-mob & {
    @include bp(0 7) {
      display: none;
    }
  }
  .dash--sidebar-mob-open & {
    @include bp(0 7) {
      display: block;
    }
  }
  &--skinny {
    @include bp(7) {
      @include size(60px _)
    }
  }
  &__navigation {
    @include padding(30px 15px 20px);
    direction: ltr;
    @include bp(0 7) {
      @include padding(15px 15px 20px);
    }
    .dash--nav-not-hover & {
      @include bp(7) {
        padding: 0;
      }
    }
  }
  &__list {
    margin: 0;
    padding: 0;
  }
  &__list-item {
    @include font(500 15px "Roboto");
    list-style: none;
    cursor: pointer;
    color: #465674;
    &:not(.sidebar__list-item--submenu) {
      &:hover {
        .sidebar-li-main__main-icon,
        .sidebar-li-main__text,
        .sidebar-li-main__arrow-icon {
          color: #404856;
          fill: #404856;
        }
      }
    }
    &--separator {
      @include margin(10px _ _);
      @include padding(10px _ _);
      border-top: 1px solid rgba(71,134,255,.15);
    }
  }
  &__app-version {
    @include font(500 14px "Roboto");
    @include margin(40px _ 20px _);
    @include padding(_ 15px);
    color: #465674;
    opacity: .7;
    text-align: center;
    .dash--compact.dash--nav-not-hover & {
      @include bp(7) {
        width: 0;
        opacity: 0;
        overflow: hidden;
      }
    }
  }
  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(101, 153, 254, .3);
  }
  &::-webkit-scrollbar-thumb {
    background-color: #6599fe;
    border-radius: 20px;
    border-left: 1px solid #fff;
  }
}
