
          @import "@/assets/scss/mixins/absolute.scss";
          @import "@/assets/scss/mixins/bp.scss";
          @import "@/assets/scss/mixins/ellipsis.scss";
          @import "@/assets/scss/mixins/fixed.scss";
          @import "@/assets/scss/mixins/font.scss";
          @import "@/assets/scss/mixins/grid-gap.scss";
          @import "@/assets/scss/mixins/margin.scss";
          @import "@/assets/scss/mixins/padding.scss";
          @import "@/assets/scss/mixins/radius.scss";
          @import "@/assets/scss/mixins/relative.scss";
          @import "@/assets/scss/mixins/size.scss";
          @import "@/assets/scss/mixins/sticky.scss";
          @import "@/assets/scss/functions/rem.scss";
          @import "@/assets/scss/global/normalize.scss";
          @import "@/assets/scss/global/fonts.scss";
          @import "@/assets/scss/global/custom.scss";
        




























































































.sidebar-li-main {
  &__item {
    @include size(100% _);
    @include padding(11px 15px);
    @include radius(6px);
    @include grid-gap(10px);
    background: #fff;
    color: #465674;
    display: grid;
    grid-template-columns: min-content auto min-content;
    align-items: center;
    .sidebar__list-item--submenu & {
      background: #ecf2ff;
      color: #6599fe;
    }
    .sidebar__list-item--active & {
      .dash--compact.dash--nav-not-hover & {
        @include bp(7) {
          background: #ecf2ff;
          color: #6599fe;
        }
      }
    }
    .dash--nav-not-hover:not(.dash--sidebar-mob) & {
      @include size(100% 40px);
      display: flex;
      align-items: center;
      grid-template-columns: 1fr;
      @include bp(7) {
        justify-content: center;
        padding: 0;
      }
    }
    .dash--compact.dash--nav-not-hover & {
      @include bp(7) {
        background: #fff;
        color: #465674;
      }
    }
    &.router-link-active {
      &:hover {
        .sidebar-li-main__main-icon {
          fill: #6599fe;
        }
        .sidebar-li-main__text {
          color: #6599fe;
        }
      }
    }
  }
  .router-link-active {
    .sidebar-li-main__main-icon {
      fill: #6599fe;
    }
    .sidebar-li-main__text {
      color: #6599fe;
    }
  }
  &__main-icon {
    @include size(14px);
    fill: #abbdcc;
    pointer-events: none;
    .sidebar__list-item--submenu & {
      fill: #6599fe;
    }
    .sidebar__list-item--active & {
      .dash--compact.dash--nav-not-hover & {
        @include bp(7) {
          fill: #6599fe;
        }
      }
    }
    .dash--compact.dash--nav-not-hover & {
      @include bp(7) {
        fill: #abbdcc;
      }
    }
  }
  &__text {
    pointer-events: none;
    .dash--compact & {
      display: none;
    }
    .dash--nav-hover &,
    .dash--sidebar-mob & {
      display: block;
    }
  }
  &__arrow-icon {
    @include size(11px);
    fill: #abbdcc;
    transform: rotate(90deg);
    transition: all 300ms;
    pointer-events: none;
    .sidebar__list-item--submenu & {
      fill: #6599fe;
      transform: rotate(0deg);
    }
    .dash--compact & {
      display: none;
    }
    .dash--nav-hover &,
    .dash--sidebar-mob & {
      display: block;
    }
  }
}
