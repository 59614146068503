
          @import "@/assets/scss/mixins/absolute.scss";
          @import "@/assets/scss/mixins/bp.scss";
          @import "@/assets/scss/mixins/ellipsis.scss";
          @import "@/assets/scss/mixins/fixed.scss";
          @import "@/assets/scss/mixins/font.scss";
          @import "@/assets/scss/mixins/grid-gap.scss";
          @import "@/assets/scss/mixins/margin.scss";
          @import "@/assets/scss/mixins/padding.scss";
          @import "@/assets/scss/mixins/radius.scss";
          @import "@/assets/scss/mixins/relative.scss";
          @import "@/assets/scss/mixins/size.scss";
          @import "@/assets/scss/mixins/sticky.scss";
          @import "@/assets/scss/functions/rem.scss";
          @import "@/assets/scss/global/normalize.scss";
          @import "@/assets/scss/global/fonts.scss";
          @import "@/assets/scss/global/custom.scss";
        






































































.dash-title {
  cursor: pointer;
  direction: ltr;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  @include bp(7) {
    @include size(240px 88px);
    @include fixed(0 _ _ 0);
  }
  .dash--compact & {
    @include size(60px 60px);
    background: var(--enviromentColor, #6599fe);
  }
  &__logo-full {
    width: 8rem;
    height: auto;
    fill: var(--enviromentColor, #0C8CCD);
    position: relative;
    top: .45rem;
  }
  &__logo-short {
    fill: #fff;
    padding: .7rem;
  }
  &__text {
    @include font(400 24px "Roboto");
    color: #465674;
    pointer-events: none;
  }
  &__text-compact {
    @include font(600 34px "Roboto");
    color: #fff;
    pointer-events: none;
  }
  &::after {
    .dash:not(.dash--compact) & {
      @include absolute(_ _ 0 20px, 1);
      @include size(calc(100% - 40px) 1px);
      content: '';
      background: #d1dbe4;
    }
  }
  &__strong {
    @include margin(_ 5px _ _);
    font-weight: 700;
    color: var(--enviromentColor, #6599fe);
  }

  &__enviroment-mark {
    display: block;
    font-size: 1rem;
    color: var(--enviromentColor, #6599fe);
    &__api-url {
      font-size: rem(12px);
    }
  }
}
