
          @import "@/assets/scss/mixins/absolute.scss";
          @import "@/assets/scss/mixins/bp.scss";
          @import "@/assets/scss/mixins/ellipsis.scss";
          @import "@/assets/scss/mixins/fixed.scss";
          @import "@/assets/scss/mixins/font.scss";
          @import "@/assets/scss/mixins/grid-gap.scss";
          @import "@/assets/scss/mixins/margin.scss";
          @import "@/assets/scss/mixins/padding.scss";
          @import "@/assets/scss/mixins/radius.scss";
          @import "@/assets/scss/mixins/relative.scss";
          @import "@/assets/scss/mixins/size.scss";
          @import "@/assets/scss/mixins/sticky.scss";
          @import "@/assets/scss/functions/rem.scss";
          @import "@/assets/scss/global/normalize.scss";
          @import "@/assets/scss/global/fonts.scss";
          @import "@/assets/scss/global/custom.scss";
        






















































































































































.sidebar-list-nested {
  @include padding(_ _ _ 20px);
  display: none;
  .sidebar__list-item--submenu & {
    display: block;
  }
  .dash--nav-not-hover & {
    @include bp(7) {
      display: none;
    }
  }
  &__list-item {
    @include font(500 15px "Roboto");
    list-style: none;
    cursor: pointer;
    color: #465674;
    .sidebar-list-nested & {
      @include margin(15px _ _);
      @include font(500 14px "Roboto");
      &:last-child {
        @include margin(_ _ 15px);
      }
    }
    &--czech {
      .sidebar-list-nested--cz & {
        color: #6599fe;
      }
    }
    &--english {
      .sidebar-list-nested--en & {
        color: #6599fe;
      }
    }
    &--slovak {
      .sidebar-list-nested--sk & {
        color: #6599fe;
      }
    }
    &.disabled {
      pointer-events: none;
      opacity: .4;
    }
  }
  &__link {
    @include font(500 14px "Roboto");
    color: #465674;
    display: block;
    .sidebar-list-nested & {
      color: #838383;
      &--active,
      &.active {
        color: #6599fe;
      }
      &:not(.router-link-active):not(.sidebar-list-nested__link--active) {
        &:hover {
          color: #404856;
        }
      }
      &.all-articles:not(.sidebar-list-nested__link--active) {
        color: #838383;
        &:hover {
          color: #404856;
        }
      }
    }
  }
}
