
          @import "@/assets/scss/mixins/absolute.scss";
          @import "@/assets/scss/mixins/bp.scss";
          @import "@/assets/scss/mixins/ellipsis.scss";
          @import "@/assets/scss/mixins/fixed.scss";
          @import "@/assets/scss/mixins/font.scss";
          @import "@/assets/scss/mixins/grid-gap.scss";
          @import "@/assets/scss/mixins/margin.scss";
          @import "@/assets/scss/mixins/padding.scss";
          @import "@/assets/scss/mixins/radius.scss";
          @import "@/assets/scss/mixins/relative.scss";
          @import "@/assets/scss/mixins/size.scss";
          @import "@/assets/scss/mixins/sticky.scss";
          @import "@/assets/scss/functions/rem.scss";
          @import "@/assets/scss/global/normalize.scss";
          @import "@/assets/scss/global/fonts.scss";
          @import "@/assets/scss/global/custom.scss";
        

















































































.tooltip-custom {
  position: relative;
  width: 14px;
  height: 14px;
  font-size: 14px;
  white-space: normal;
}

.tooltip-custom.absolute {
  position: absolute;
  margin-left: 2px;
}

.tooltip-custom:hover .tooltip-custom--info {
  color: rgb(132, 144, 167);
  visibility: visible;
  opacity: 1;
}

.tooltip-custom--info {
  display: block;
  padding: 10px;
  background: #fff;
  border-radius: 3px;
  position: absolute;
  visibility: hidden;
  margin-left: -100px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, .2);
  transition: all .15s ease-in-out;
  opacity: 0;
  z-index: 99;

  &--fixed-width {
    width: 200px;
  }

  &--align-center {
    text-align: center;
  }

  &--align-left {
    text-align: left;
  }

  &--placement-bottom {
    top: 26px;
    right: 50%;
    transform: translate(50%, 0);
  }

  > span {
    font-weight: 400;
    font-size: 12px;
  }

  .tooltip-custom--arrow {
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #fff;

    &--placement-top {
      top: 100%;
      left: calc(50% - 10px);
    }

    &--placement-left {
      right: -14px;
      transform: rotate(270deg);
      top: calc(50% - 5px);
    }

    &--placement-bottom {
      bottom: 100%;
      transform: rotate(180deg);
      left: calc(50% - 10px);
    }
  }

  &.seo {
    text-align: justify;
    line-height: 130%;
    font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    font-size: 14px;
    width: 400px;
  }
}
