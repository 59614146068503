
          @import "@/assets/scss/mixins/absolute.scss";
          @import "@/assets/scss/mixins/bp.scss";
          @import "@/assets/scss/mixins/ellipsis.scss";
          @import "@/assets/scss/mixins/fixed.scss";
          @import "@/assets/scss/mixins/font.scss";
          @import "@/assets/scss/mixins/grid-gap.scss";
          @import "@/assets/scss/mixins/margin.scss";
          @import "@/assets/scss/mixins/padding.scss";
          @import "@/assets/scss/mixins/radius.scss";
          @import "@/assets/scss/mixins/relative.scss";
          @import "@/assets/scss/mixins/size.scss";
          @import "@/assets/scss/mixins/sticky.scss";
          @import "@/assets/scss/functions/rem.scss";
          @import "@/assets/scss/global/normalize.scss";
          @import "@/assets/scss/global/fonts.scss";
          @import "@/assets/scss/global/custom.scss";
        





































































































































.site-select {
  &__label {
    font-family: "Roboto", sans-serif;
    font-size: rem(14px);
    font-weight: 400;
    color: #8A96AC;

    &--error {
      color: var(--danger);
    }
  }

  ::v-deep {
    .multiselect {
      .multiselect__tags {
        border: 1px solid #d1dbe4;
      }

      &--error {
        .multiselect__tags {
          border: 1px solid var(--danger);
        }
      }

      &__option {
        &:has(.site-select__item-divider) {
          margin-top: rem(8px);
        }
      }

      .multiselect__single, .multiselect__placeholder {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #465674
      }

      .multiselect__placeholder, .multiselect__input, ::placeholder {
        color: lighten(#465674, 30%);
      }
    }
  }

  &__item-divider {
    position: relative;

    &__divider {
      background-color: #000;
      height: 1px;
      left: 0;
      position: absolute;
      top: rem(-16px);
      width: 100%;
    }
  }
}
